import { useMemo } from 'react'

import prepareAssetList from 'src/js/assets/prepareAssetList'
import useFetchAssetsData from 'src/js/assets/hooks/useFetchAssetsData'

const useFetchAssets = () => {
  const { dataObjects, withExchange, withApy, withPrice, withCurrentPrice, err } =
    useFetchAssetsData()

  // build assets array.
  const assets = useMemo(() => {
    return prepareAssetList(dataObjects, withApy, withExchange, withPrice, withCurrentPrice)
  }, [withApy, withExchange, withPrice, withCurrentPrice])

  // combined assets.
  const combined = useMemo(() => {
    return prepareAssetList(dataObjects, withApy, withExchange, withPrice, withCurrentPrice, true)
  }, [withApy, withExchange, withPrice, withCurrentPrice])

  // if error, return with log.
  if (err) return console.error(err)
  return {
    assets: assets.assets,
    combinedAssets: combined.assets,
    newAssets: assets.new,
    count: assets.count,
    combinedCount: combined.count,
  }
}

export default useFetchAssets
