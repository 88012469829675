/* Load the data */

const fetchAssetsCount = ({ count, platform = null, floorTo5 = true } = {}) => {
  /* find the closest factor 5 */
  const factoring = (num) => Math.floor((num - 1) / 5) * 5

  /* return assets supported count for each wallet */
  switch (platform) {
    case 'mobile':
      return floorTo5 ? factoring(count.mobile) : count.mobile
    case 'trezor':
      return floorTo5 ? factoring(count.trezor) : count.trezor
    case 'desktop':
    default:
      return floorTo5 ? factoring(count.desktop) : count.desktop
  }
}

export default fetchAssetsCount
